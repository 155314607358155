<template>
  <ModalWindow
    :class="[cartStore.cartError ? 'modal-delete-warning' : 'cart-modal full-window']"
    @closed="cartStore.closeCartModal()"
  >
    <ModalBodyError
      v-if="cartStore.cartError"
      :cart-error="cartStore.cartError"
      class="modal-body"
      @emit-close="closeErrorModal"
    />
    <div v-else class="modal-body a2c">
      <div class="preview-cart">
        <section class="preview-cart-checkout flex-col">
          <div class="cart-modal-content">
            <div class="h3 modal-header-title flex-inline">
              <template v-if="cartStore.numItemsCart > 0"> Your Cart ({{ cartStore.numItemsCart }}) </template>
              <template v-else> Your Cart Is Empty </template>
            </div>

            <!-- Dynamic product -->
            <dl class="product-view-details">
              <BuyMoreSaveMore />

              <template v-if="cartInProgress">
                <div>
                  <div
                    v-for="(item, idx) in state.latestNumLineItems"
                    :key="idx + 'container'"
                    class="skeleton skeleton-section item-skeleton"
                  />
                </div>
              </template>
              <template v-else>
                <div v-for="(item, i) in cartModalProducts" :key="item.title + item.sku + i" class="flex cart-product">
                  <NuxtLink :to="localePath(item.url)" @click="cartStore.closeCartModal()">
                    <CloudinaryImage
                      v-if="item"
                      :public-id="item.image"
                      :options="{ width: 80, height: 80, quality: 'auto', transform: 'pad' }"
                      :alt="item.title"
                      :image-classes-override="['product-view-image-cart']"
                    />
                  </NuxtLink>
                  <div class="flex-col">
                    <dt class="product-view-title flex-col mgn-btm-pt25">
                      <span class="title">{{ item.title }}</span>
                      <span v-if="item.whatsIncluded" class="variation-detail whats-included"
                        >Includes: {{ item.whatsIncluded }}</span
                      >
                      <template v-if="item?.configuration?.length">
                        <span
                          v-for="option in optConfigDisplay(item.configuration)"
                          :key="option.nameId + option.value"
                          class="variation-detail"
                        >
                          {{ option.name + ': ' + option.value }}
                          <div
                            v-if="checkForEtchConfig(option.name, item.configuration.length)"
                            role="button"
                            aria-label="remove etching"
                            class="remove-button remove-etch pointer"
                            @click="removeEtching(item)"
                          >
                            Remove Etching
                          </div>
                        </span>
                      </template>
                      <span v-if="getSubData(item)" class="frequency">{{ frequency(getSubData(item)) }}</span>
                    </dt>

                    <CartPricing :product="item" />
                    <span v-if="item.backorderMessage" class="backorder-msg">{{ item.backorderMessage }}</span>

                    <div class="flex quantity-container">
                      <Stepper
                        v-if="!isDiscountFree(item) && !isGiftCard(item)"
                        :counter-start="item.qty"
                        :min="0"
                        :max="item?.max ? item.max + item.qty - numBaseProdInCart(item) : undefined"
                        @stepper-update="updateQuantity($event, item)"
                      />
                      <div v-else-if="!isGiftCard(item)" class="product-view-price quantity-text">
                        Quantity: {{ item.qty }}
                      </div>
                      <div>
                        <CustomButton
                          v-if="!isDiscountFree(item)"
                          :class="'remove-button' + giftMargin(item)"
                          aria-label="Remove from Cart"
                          style-color="black"
                          style-name="tertiary"
                          @click-button="removeFromCart(item)"
                        >
                          Remove
                        </CustomButton>
                      </div>
                    </div>
                    <!-- <LazyUrgencyMessaging
                      v-if="item?.urgencyMessage?.message"
                      :urgency-messaging="item.urgencyMessage"
                    /> -->
                  </div>
                </div>
              </template>
            </dl>

            <GiftWithPurchase v-if="testStore.giftWithPurchaseTest" />

            <div class="checkout-details-section">
              <div class="h4 order-summary">{{ useTranslate('CartSlideout.orderSummary') }}</div>
              <CheckoutDetails v-if="cartStore.numItemsCart > 0" />
              <!-- <CheckoutButton class="content-section-button" /> -->
              <CouponCode />
              <LazyCartError v-if="cartStore.cartError" />
            </div>

            <div class="rewards-wrapper">
              <FireStarterRewardsCart />
            </div>

            <div class="freq-added">
              <ComponentSection
                v-for="(comp, i) in cartProductRecs"
                :key="'cart-modal-product-rec-' + i"
                :section-entry="comp[Object.keys(comp)[0]]"
                :section-key="Object.keys(comp)[0]"
              />
            </div>
          </div>

          <div class="bottom-section">
            <FreeShipping />

            <div class="preview-cart-checkout-price-label flex-inline between mgn-btm-pt75">
              <div>
                <span class="h5 total-label">{{ useTranslate('CartSlideout.total') }}</span> ({{
                  cartStore.numItemsCart
                }}
                {{ cartStore.numItemsCart > 1 || cartStore.numItemsCart === 0 ? 'Items' : 'Item' }}):
              </div>
              <div class="preview-cart-checkout-price" :class="[{ skeleton: totalPlusTax === undefined }]">
                <div class="h5 total">
                  {{ totalPlusTax }}
                </div>
              </div>
            </div>

            <div :class="['btn-container flex-inline checkout-only']">
              <CheckoutButton />
            </div>
          </div>
        </section>
      </div>
    </div>
  </ModalWindow>
</template>

<script setup lang="ts">
import { capitalize } from 'lodash-es';
import { useRootStore } from '~/store';
import { useCartStore } from '~/store/cart';
import { useTestStore } from '~/store/test';
// import { useConnectionStore } from '~/store/connection';
import { useLocaleStore } from '~/store/locale';
import { useDyStore } from '~/store/dy';
import { unlockBody } from '~/util/eventHandler';
import { optionConfigDisplay } from '~/util/frontend';
import { refreshKlarna } from '~/util/klarna';
import { checkIsDiscountFree, numBaseProductsInCart /*, findEtchingSurcharge */ } from '~/util/bigcommerce';
import { CartObj } from '~/types/bigcommerce';
import { mapBcCartItemsToGtmEvent, GtmEventType } from '~/util/analytics';

const localePath = useLocalePath();
const rootStore = useRootStore();
const cartStore = useCartStore();
const testStore = useTestStore();
// const connectionStore = useConnectionStore();
const localeStore = useLocaleStore();
const dyStore = useDyStore();
const gtm = useGtm();

provide('isCartModal', 'true');

const state = reactive({
  showCartDeleteWarning: false,
  couponIsOpen: false,
  couponCode: '',
  latestNumLineItems: 2,
});

const cartActionInProgress = computed(() => cartStore.cartActionInProgress);
const gettingCart = computed(() => cartStore.gettingCart);
const cartInProgress = computed<boolean>(() => cartActionInProgress.value || gettingCart.value);
const cartModalProducts = computed<any[]>(() => {
  if (cartStore.filteredProducts?.length) {
    const arrCopy = [...cartStore.filteredProducts];
    return arrCopy.reverse(); // show recently added first
  }
  return cartStore.filteredProducts;
});
const currencySymbol = computed<string>(() => localeStore.currencySymbol);
// localeStore.currencySymbol + formatPrice(cartStore.totalPlusTax)
const totalPlusTax = computed<string | undefined>(() => {
  return cartStore.totalPlusTax ? `${currencySymbol.value || ''}${formatPrice(cartStore.totalPlusTax)}` : undefined;
});
const cartProductRecs = computed<any>(() => rootStore.brandSettings?.cart_product_recs?.section || []);

watch(
  () => cartActionInProgress.value,
  (updated) => {
    state.latestNumLineItems = cartStore.products?.length || 0;
    if (!updated) {
      updateDy();
    }
  }
);

watch(
  () => cartStore.gettingCart,
  () => {
    state.latestNumLineItems = cartStore.products?.length || 0;
  }
);

watch(
  () => cartModalProducts.value,
  (updated, old) => {
    if (updated?.length !== old?.length && updated?.length > 0) {
      updateDy();
    }
  }
);

async function nextTickRefreshKlarna() {
  await nextTick(); // makes sure 'data-purchase-amount' on klarna html has updated in DOM
  refreshKlarna();
}
async function updateQuantity(quantity: number, product: any): Promise<void> {
  const previousQty = product.qty;
  const data: CartObj = {
    previousQty,
    quantity,
    product,
  };
  data.product.qty = quantity;
  if (quantity >= product.min) {
    await useUpdateCartItem(data, previousQty > quantity);
    gaViewCart();
    await nextTickRefreshKlarna();
  } else {
    await removeFromCart(product);
  }
}
function gaViewCart() {
  gtm?.trackEvent(mapBcCartItemsToGtmEvent(GtmEventType.viewCart, cartStore.filteredProducts));
}
async function removeFromCart(product: any): Promise<void> {
  await useDeleteCartItem({ cartItem: { product } });
  await nextTickRefreshKlarna();
}
async function removeEtching(product: any) {
  // according to bc you need to remove/re-add the product to edit modifiers
  // skip setting true at the end of delete to avoid flicker of loading state
  await useDeleteCartItem({ cartItem: { product }, skipCartActionComplete: true });
  await addEditedItemToCart(product);
  await nextTickRefreshKlarna();
}
async function addEditedItemToCart(product: any) {
  // only used for products with custom modifiers
  // need to map back to cart data structure
  const hasVariants = !!product?.variant_id;
  const cartProduct = [
    {
      quantity: product.qty,
      product: {
        bc_product: {
          id: product.id,
        },
        image: product.image,
      },
      hasVariants,
      ...(hasVariants && { variant: { bcId: product.variant_id } }),
      // DONT ADD OPTIONSELECTIONS (ref in mapProductAddToCart in cart.js store)
    },
  ];
  await useAddToCart({ cartItems: cartProduct });
  // START - Prevents cart modal on cart page. Scrolls to top on a2c action.
  // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  // cartStore.setNewItemAdded', false);
  // END
}
function updateDy() {
  const { langCode } = useLocaleStore();
  dyStore.updateRecChoices(langCode, true);
}
function formatPrice(price: number | string): string {
  const priceToFormat = typeof price === 'string' ? parseFloat(price) : price;
  return priceToFormat ? priceToFormat.toFixed(2) : price.toString();
}
function isDiscountFree(product: any) {
  return checkIsDiscountFree(product);
}
function isGiftCard(product: any) {
  return product.sku === 'SS-GIFT-CARD';
}
function giftMargin(product: any) {
  if (isGiftCard(product)) return ' remove-button--gift-card';
  else return '';
}
function numBaseProdInCart(product: any): number {
  return numBaseProductsInCart(cartStore.products, product);
}
function checkForEtchConfig(name: string, configLength: number) {
  const ls = name?.toLowerCase();
  return (configLength > 2 && ls === 'college logo selection') || ls === 'customer etching text';
}
function frequency(subData: any): string | undefined {
  const prefs = subData?.subscription_preferences;
  const interval = capitalize(prefs?.interval_unit || '');
  const frequency = prefs?.order_interval_frequency;
  return interval && frequency ? `Delivered Every ${frequency} ${interval}s` : undefined;
}
function getSubData(item: any) {
  return item?.subData || item?.latestAddition?.product?.subData;
}
function closeErrorModal() {
  unlockBody();
  state.showCartDeleteWarning = false;
  cartStore.setNewItemAdded(false);
}
function optConfigDisplay(configArr: any[]): any[] {
  return optionConfigDisplay(configArr);
}
</script>

<style lang="scss" scoped>
$desk-padding: 1.5rem;

:deep(.content-section-button.checkout-btn-wrapper) {
  width: 100%;
  margin-top: 1rem;
  .btn {
    width: 100%;
  }
}

.item-skeleton {
  height: 130px;
}

.btn-container {
  gap: 0.5rem;
  :deep(.checkout-btn-wrapper) {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}

.modal-body {
  width: 100%;
  height: 100%;
  &.a2c {
    background-color: #f5f7fa;
  }
  .product-view-details {
    .cart-product {
      align-items: flex-start;
      + .cart-product {
        border-top: 1px solid $color-neutral-cool-100;
      }
      &:last-child {
        border-bottom: 1px solid $color-neutral-cool-100;
      }
    }
  }

  .modal-header-title,
  .cart-product,
  .bottom-section,
  .gwp-container,
  .checkout-details-section,
  .rewards-wrapper {
    background-color: $color-neutral-white;
  }

  .modal-header-title,
  .cart-product,
  .freq-added,
  .gwp-container,
  .checkout-details-section,
  .rewards-wrapper {
    padding: 1.5rem 1rem;
    margin: 0 -1rem;
    @include local-mixins.desktop {
      padding: 1.5rem 1.5rem;
      margin: 0 -1.5rem;
    }
  }
  .modal-header-title {
    text-align: center;
    border-bottom: 1px solid $color-neutral-cool-100;
    padding: 1rem;
    @include local-mixins.desktop {
      padding: 1rem $desk-padding 1rem $desk-padding;
    }
  }

  .rewards-wrapper {
    padding-top: 0;
    padding-bottom: 1rem;
    .rewards-section {
      margin-bottom: 0;
    }
  }

  .cart-btn {
    height: 60px;
  }

  .free-shipping-threshold {
    margin-bottom: 0.75rem;
  }

  .cart-modal-content {
    // max-height: 80vh;
    overflow: auto;
  }

  .freq-added {
    background-color: #f5f7fa;
  }

  .preview-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .preview-cart-checkout {
      height: 100%;
    }
    .product-view,
    .preview-cart-checkout {
      width: 100%;
    }
  }
}

.cart-modal-content,
.bottom-section {
  padding: 0 1rem 1rem 1rem;
  @include local-mixins.desktop {
    padding: 0 $desk-padding $desk-padding $desk-padding;
  }
}

.bottom-section {
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid $color-neutral-cool-100;
}

:deep(.product-view-image-cart) {
  display: flex;
  margin: 0 1rem 0 0;
  width: 80px !important;
  height: auto !important;
  border-radius: 4px;
  background-color: $color-neutral-cool-50 !important;
}

.product-view-title {
  gap: 4px;
  .title {
    font-weight: 500;
    font-size: 1rem;
  }
  .frequency {
    display: block;
    font-size: #{local-functions.rem-calc(13)};
  }
}

:deep(.cart-price-section) .prices .bold,
:deep(.cart-price-section) .prices .strikethrough,
.product-view-price,
.product-view-price .strikethrough {
  font-size: 0.875rem;
  font-weight: normal;
}
.quantity-container {
  align-items: center;
  margin-top: 0.5rem;
  :deep(.stepper-comp-wrapper) {
    button {
      width: 22px;
      height: 22px;
    }
    input {
      width: 24px;
      height: 22px;
      padding: 0;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

.remove-button {
  font-size: 0.75rem;
  align-self: flex-start;
  margin-left: 1.5rem;
  width: auto;
  text-transform: capitalize;
  text-decoration: underline;
  font-weight: 400;
  &.remove-etch {
    color: $color-primary-600;
    margin-left: 0;
  }
}
.remove-button--gift-card {
  margin-left: 0;
}

.check {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  path {
    fill: $color-success-default;
  }
}

.variation-detail,
.backorder-msg,
.quantity-text {
  line-height: 1.5;
}

.product-title {
  font-size: 0.875rem;
}

.variation-detail,
.backorder-msg {
  font-size: 0.75rem;
}

:deep(.coupon-section) {
  margin-bottom: 0.75rem;
  .coupon-header {
    font-size: 0.875rem;
  }
}

.backorder-msg {
  color: $color-primary-600;
}

.preview-cart-checkout-price {
  .total,
  .total-label {
    margin-bottom: 0;
  }
}
</style>
